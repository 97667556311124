import $ from 'jquery';
import 'jquery.rut';
import Inputmask from 'inputmask';

const validations = () => {

  $(function () {
    $("#user_rut").rut({
      formatOn: 'keyup',
      minimumLength: 8,
      validateOn: 'change keyup'
    }).on('rutInvalido', function () {
      $(this).removeClass("is-invalid");
      $(this).addClass("is-invalid");
    })
      .on('rutValido', function () {
        $(this).removeClass("is-invalid");
        $(this).addClass("is-valid");
      });

    $("#company_rut").rut({
      formatOn: 'keyup',
      minimumLength: 8,
      validateOn: 'change keyup'
    }).on('rutInvalido', function () {
      $(this).removeClass("is-invalid");
      $(this).addClass("is-invalid");
      resetAutocomplete();
    })
      .on('rutValido', function (e, rut, dv) {
        $(this).removeClass("is-invalid");
        $(this).addClass("is-valid");
        autocomplete(`${rut}-${dv}`);
      });

    $("#company_legal_representative_rut").rut({
      formatOn: 'keyup',
      minimumLength: 8,
      validateOn: 'change keyup'
    }).on('rutInvalido', function () {
      $(this).removeClass("is-invalid");
      $(this).addClass("is-invalid");
      //resetAutocomplete();
    })
      .on('rutValido', function (e, rut, dv) {
        $(this).removeClass("is-invalid");
        $(this).addClass("is-valid");
        //autocomplete(`${rut}-${dv}`);
      });

      $("#contracts_employee_rut").rut({
        formatOn: 'keyup',
        minimumLength: 8,
        validateOn: 'change keyup'
      }).on('rutInvalido', function () {
        $(this).removeClass("is-invalid");
        $(this).addClass("is-invalid");
        resetAutocomplete();
      })
        .on('rutValido', function (e, rut, dv) {
          $(this).removeClass("is-invalid");
          $(this).addClass("is-valid");
          autocomplete(`${rut}-${dv}`);
        });
    
    $("#ending_name").on( function(){

      endRelationshipAutocomplete("nombre")      
    })

    const autocomplete = (rutAndDV) => {
      jQuery.getJSON(`${window.location.origin}/consulta_sii`, { rut: rutAndDV }, function (result) {
        $("#company_business_name").val(result['razon_social']);
        const arrGiros = result['actividades'].map((act) => act['giro']);
        $("#company_line_of_business").replaceWith(`<select id="company_line_of_business" name="company[line_of_business]" class="form-select select required" required="required" aria-required="true">
          ${ arrGiros.map((giro) => `<option value="${giro}">${giro}</option>`)}
          </select>`);
      });
    }

    const resetAutocomplete = () => {
      $("#company_business_name").val("");
      $("#company_line_of_business").replaceWith('<input class="form-control string required" required="required" aria-required="true" type="text" name="company[line_of_business]" id="company_line_of_business"></input>');
    }

    const endRelationshipAutocomplete = (companyName) => {
      $("#ending_name").val("NOMBRE");
    }

    const userPhone = new Inputmask("(+99) 9 9999 9999", {
      //placeholder: "(+56) 9 1234 5678",
      //clearIncomplete: true,
      removeMaskOnSubmit: true
    }).mask($("#user_phone"));

    const companyPhone = new Inputmask("(+99) 9 9999 9999", {
      //placeholder: "(+56) 9 1234 5678",
      //clearIncomplete: true,
      removeMaskOnSubmit: true
    }).mask($("#company_phone"));


    const customerPhone = new Inputmask("(+99) 9 9999 9999", {
      //placeholder: "(+56) 9 1234 5678",
      //clearIncomplete: true,
      removeMaskOnSubmit: true
    }).mask($("#customer_phone"));

    const legalRepresentativePhone = new Inputmask("(+99) 9 9999 9999", {
      //placeholder: "(+56) 9 1234 5678",
      clearIncomplete: true,
      removeMaskOnSubmit: true
    }).mask($("#company_legal_representative_phone"));

    const legalRepresentativeEmail = new Inputmask({
      alias: "email",
      //clearIncomplete: true,
      removeMaskOnSubmit: true
    }).mask($("#company_legal_representative_email"));


    // It doesn't support type='email'
    /*
    const userEmail = new Inputmask({
      alias: "email",
      clearIncomplete: true,
      removeMaskOnSubmit: true
    }).mask($("#user_email"));
    */
  });

}

export { validations };
